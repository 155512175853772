import React from 'react'
import './pertenecen.css'

export default function Pertenecen() {
  return (
    <div className='containerEmpresas' id='SectionAliados'>
        <div className='headEmpresas'>
            <h1>Confían en nosotros</h1>
        </div>
        <div className='carrusel-contenido' id='carrusel-contenido'>
            <div className="carousel-inner">
                <img className='imgElement1' src={process.env.PUBLIC_URL + 'LogoINVESA.png'} alt="Invesa.png"/>
                <img className='imgElement2' src={process.env.PUBLIC_URL + 'LogoGrupoJaibu.png'} alt="GruoJaibu.png"/>
                <img className='imgElement3' src={process.env.PUBLIC_URL + 'LogoSulfoquimica.png'} alt="Sulfoquimica.png"/>
                <img className="imgElement4" src={process.env.PUBLIC_URL + 'LogoAuteco.png'} alt="Auteco.png"/>
                <img className="imgElement5" src={process.env.PUBLIC_URL + 'LogoTintatex.png'} alt="Tintatex.png"/>
                <img className="imgElement6" src={process.env.PUBLIC_URL + 'LogoEstra.png'} alt="Estra.png"/>
                <img className="imgElement7" src={process.env.PUBLIC_URL + 'LogoIntegralesColombia.png'} alt="IntegralesColombia.png"/>
            </div>
        </div>
    </div>
  )
}