import React, { useState, useEffect, useRef } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const pagesAndVisuals_rol_freemium = [
{
    "pageName": "ReportSection791741eeb468a61d3d2c",
    "visuals": [
    {
        "name": "498d8580562a2280dd22",
        "title": "Imagen"
    },
    {
        "name": "5125c88c07a61b01d294",
        "title": "desfiltrado"
    },
    {
        "name": "f26df68669b2ab6fa90b",
        "title": "Filtro visualizar"
    },
    {
        "name": "b5c18cfbda397d2c97e3",
        "title": "Distribución del costo por tipología"
    },
    {
        "name": "a1197282d9b22098848d",
        "title": "Distribución del costo por servicio"
    },
    {
        "name": "9164554436568059e748",
        "title": "Presupuesto anual"
    },
    {
        "name": "7126228623b003609c47",
        "title": "Costo total por mes"
    },
    {
        "name": "7092ab882aea49433d58",
        "title": "Cuentas"
    },
    {
        "name": "2bdc49b474d60c835871",
        "title": "Variación %"
    },
    {
        "name": "66bedad7d42f34cf1019",
        "title": "Costo año anterior"
    },
    {
        "name": "56b01816a043c6345728",
        "title": "Costo año actual"
    },
    {
        "name": "68ea7e5e5078647cec54",
        "title": "Distribución del costo por departamento / municipio"
    },
    {
        "name": "bb40ef89b39b83244b30",
        "title": "Top Costo"
    }
    ]
},
{
    "pageName": "ReportSection2131e7c42e830b758096",
    "visuals": [
    {
        "name": "3c4a9c344eeb4bddab60",
        "title": "Distribución del costo de energía por tipo"
    },
    {
        "name": "6af8d65606b5d86caceb",
        "title": "Distribución del costo de energía por tipología"
    },
    {
        "name": "a905e4de9b052c010ba6",
        "title": "Variación %"
    },
    {
        "name": "95b1ec6777697928163a",
        "title": "Costo de energía por mes"
    },
    {
        "name": "30ddf5cd5016595e0db7",
        "title": "Costo año actual"
    },
    {
        "name": "0c81600e0c3cb3ecd23b",
        "title": "Costo año anterior"
    },
    {
        "name": "07dea4316becd6064300",
        "title": "Cuentas"
    },
    {
        "name": "34d1df1e4d104adba103",
        "title": "Distribución del costo de energía por dpto. / municipio"
    },
    {
        "name": "ef245883aaba377c0692",
        "title": "Top costo energía"
    },
      {
        "name": "f077e06643b428203b53",
        "title": "indicadores"
      },
      {
        "name": "7ce3988b457da437c8ec",
        "title": "Vista gastos"
      },
      {
        "name": "ca6f9c5cad31a3a536b8",
        "title": "Vista consumos"
      },
      {
        "name": "afc7f4f2c3e0a3c4a5e7",
        "title": "Vista tarifas"
      } 
    ]
},
{
    "pageName": "ReportSection0d40d3b0b009bb988972",
    "visuals": [
    {
        "name": "2b3f2474aacd763f7644",
        "title": "Distribución del consumo de energía por tipo"
    },
    {
        "name": "22b88697bc03892055ce",
        "title": "Distribución del consumo de energía por tipología"
    },
    {
        "name": "a3217d8adc83a825efee",
        "title": "Consumo de energía (kWh) por mes"
    },
    {
        "name": "ef53c04be24c41b92093",
        "title": "Variación %"
    },
    {
        "name": "64ca4775ae53733fc179",
        "title": "Consumo año actual (kWh)"
    },
    {
        "name": "d920cb6a6c7cd6aa62d2",
        "title": "Consumo año anterior (kWh)"
    },
    {
        "name": "4bf48b6be3b6fae3ca2d",
        "title": "Cuentas"
    },
    {
        "name": "6f7ebb65e56b58678c70",
        "title": "Distribución del consumo de energía por dpto. / municipio"
    },
    {
        "name": "a58a1a8c962cdda74301",
        "title": "Top consumo energía"
    },
    {
        "name": "e4021ba810e5dc55219a",
        "title": "indicadores"
    },
    {
        "name": "fb12445e9eebec411a8b",
        "title": "Vista gastos"
    },
    {
        "name": "2aee47f99e0c77ca6cca",
        "title": "Vista consumos"
    },
    {
        "name": "21ed7fc070293d720bde",
        "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSection99f3c2e03d59b930e260",
    "visuals": [
    {
        "name": "e9bcb9256edf27447b3d",
        "title": "Variación %"
    },
    {
        "name": "82de7e0a5aa026948a84",
        "title": "Tarifa prom. año actual ($/kWh)"
    },
    {
        "name": "fa3311ed0cc8077e1d15",
        "title": "Tarifa prom. año anterior ($/kWh)"
    },
    {
        "name": "07e404ce857279a2c544",
        "title": "Cuentas"
    },
    {
        "name": "d0683fb494dc2847b01c",
        "title": "Tarifa promedio ponderado de energía ($/kWh)"
    },
    {
        "name": "b5659fd7b3edb24e4b92",
        "title": "Top tarifas"
    },
    {
        "name": "2dfdd129a96ea33919b8",
        "title": "Distribución del costo de energía por mercado"
    },
    {
        "name": "8ed49bea1aefe63a0453",
        "title": "Distribución del costo de energía por comercializador"
    },
    {
        "name": "6e99cf1d0f9d34a38e4c",
        "title": "Distribución del costo de energía por depto. / municipio"
    },
    {
      "name": "72162449211c6365b0d0",
      "title": "indicadores"
    },
    {
      "name": "273895a4d099a86e995a",
      "title": "Vista gastos"
    },
    {
      "name": "762f9abf2e0a1e04b024",
      "title": "Vista consumos"
    },
    {
      "name": "1a45926090704178e2e2",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSectionda54143fa97ae1586828",
    "visuals": [
    {
        "name": "2d2a2a4fb9e8702fb09e",
        "title": "Variación %"
    },
    {
        "name": "63793b0df52f5ad008b6",
        "title": "KPI promedio año actual"
    },
    {
        "name": "009cc892a6ac07c37349",
        "title": "KPI promedio año anterior"
    },
    {
        "name": "58425846d98c1f44490b",
        "title": "Cuentas"
    },
    {
        "name": "2e91dc5c06081bcdd495",
        "title": "Sedes menos productivas por KPI"
    },
    {
        "name": "d2573597e3c196dda4f4",
        "title": "KPI [kWh/ton] por tipología"
    },
    {
        "name": "1d1499beed79ea73ae8b",
        "title": "KPI [kWh/m²] por tipología"
    },
    {
        "name": "0a0a655bee844a5614cd",
        "title": "Seguimiento de la variación entre el año actual y anterior"
    },
    {
        "name": "c1cacc2bb49423b07bee",
        "title": "Distribución del costo de energía por tipología"
    },
    {
      "name": "40ddb2fc899754355d00",
      "title": "indicadores"
    },
    {
      "name": "9131b592b13aa4142a8b",
      "title": "Vista gastos"
    },
    {
      "name": "b2e80fa3d16ea655e65b",
      "title": "Vista consumos"
    },
    {
      "name": "b63376da301332a5b3a5",
      "title": "Vista tarifas"
    },
    {
      "name": "bbe19e7d60b88c4565c4",
      "title": "General"
    }
    ]
},
{
    "pageName": "ReportSectiondf28c00ed18b63371ee2",
    "visuals": [
    {
        "name": "a19d544fe5f3cc3ec06b",
        "title": "Distribución del costo de agua por tipo"
    },
    {
        "name": "2d182758cbe1c4c71028",
        "title": "Distribución del costo de agua por tipología"
    },
    {
        "name": "f898194c243d9c8a031d",
        "title": "Variación %"
    },
    {
        "name": "7eb22bc3dbdc5e462582",
        "title": "Costo de agua por mes"
    },
    {
        "name": "61c0f628cc3be480dca4",
        "title": "Costo año actual"
    },
    {
        "name": "1e906dac2f1178f2fbe8",
        "title": "Costo año anterior"
    },
    {
        "name": "22349bf5480d3a8b09ae",
        "title": "Cuentas"
    },
    {
        "name": "7b00259c6bc246613d55",
        "title": "Distribución del costo de agua por departamento / municipio"
    },
    {
        "name": "f1e85270658c79007a40",
        "title": "Top costo agua"
    },
    {
      "name": "3711b3d991541613ae49",
      "title": "indicadores"
    },
    {
      "name": "d57b97b05e70e4903e7a",
      "title": "Vista gastos"
    },
    {
      "name": "b77aea1ca9b859c951a2",
      "title": "Vista consumos"
    },
    {
      "name": "c166326c0b670a4018de",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSection7835c2a2e782b84690aa",
    "visuals": [
    {
        "name": "aea33b4b00c047d22099",
        "title": "Distribución del consumo de agua por tipo"
    },
    {
        "name": "bf08c303be3ad09b2c15",
        "title": "Distribución del consumo de energía por tipología"
    },
    {
        "name": "159661700d0034843124",
        "title": "Consumo de agua (M³) por mes"
    },
    {
        "name": "63cb79dd09c667bd998e",
        "title": "Variación %"
    },
    {
        "name": "946565caeb95b3aadca0",
        "title": "Consumo año actual (M³)"
    },
    {
        "name": "56174663aa8463e33513",
        "title": "Consumo año anterior (M³)"
    },
    {
        "name": "dd152917bc16bd9e5005",
        "title": "Cuentas"
    },
    {
        "name": "9eb2e5b32dc8a3360426",
        "title": "Distribución del consumo de energía por dpto. / municipio"
    },
    {
        "name": "036491505c89344730cc",
        "title": "Top consumo agua"
    },
    {
      "name": "d4e546424cab7e001e88",
      "title": "indicadores"
    },
    {
      "name": "048124a1603cc2a025a5",
      "title": "Vista gastos"
    },
    {
      "name": "0b9984ce77be3060746a",
      "title": "Vista consumos"
    },
    {
      "name": "bfb9c7a996215e09b7ee",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSectionf2dbe8b0441debe85799",
    "visuals": [
    {
        "name": "83048fe0a50ed89108a7",
        "title": "Variación %"
    },
    {
        "name": "fbabc8753c4ea464337d",
        "title": "Tarifa prom. año actual ($/M³)"
    },
    {
        "name": "44189bdcc01c077b7268",
        "title": "Tarifa prom. año anterior ($/M³)"
    },
    {
        "name": "91db2d3c8613900cbb1d",
        "title": "Cuentas"
    },
    {
        "name": "47af547a0c7b9e826b05",
        "title": "Tarifa promedio ponderado de agua ($/M³)"
    },
    {
        "name": "78e8305be1d00106b9e3",
        "title": "Top tarifas"
    },
    {
        "name": "b22051eec60a8c4a75ec",
        "title": "Distribución del costo de agua por tipo"
    },
    {
        "name": "1ce2019c920c0e7061bc",
        "title": "Distribución del costo de agua por comercializador"
    },
    {
        "name": "a8dfd000676bbd009a40",
        "title": "Distribución del costo de agua por depto. / municipio"
    },
    {
      "name": "aa3a4fed1ac90c63c160",
      "title": "indicadores"
    },
    {
      "name": "5cc81f06879b16e5c225",
      "title": "Vista gastos"
    },
    {
      "name": "b168ead99b02e3101ae7",
      "title": "Vista consumos"
    },
    {
      "name": "f6fa81ef044a004600a8",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSection61f9066c6c2094557cb1",
    "visuals": [
    {
        "name": "1ce14192744809e75e64",
        "title": "Variación %"
    },
    {
        "name": "ef95fb9449c74d712253",
        "title": "KPI promedio año actual"
    },
    {
        "name": "a8a385db36d8eb879e79",
        "title": "KPI promedio año anterior"
    },
    {
        "name": "09fcbacfd5aa1bc9e987",
        "title": "Cuentas"
    },
    {
        "name": "2b5538e6ac954ceb14ca",
        "title": "KPI [m³/ton] por tipología"
    },
    {
        "name": "5006b9d2d49061d181ae",
        "title": "Sedes menos productivas por KPI"
    },
    {
        "name": "e19d3b25e01368e14bb9",
        "title": "KPI [m³/m²] por tipología"
    },
    {
        "name": "6d65dfaa503dc870021b",
        "title": "Seguimiento de la variación entre el año actual y anterior"
    },
    {
        "name": "6cbbe055050de2125c00",
        "title": "Distribución del costo de agua por tipología"
    },
    {
      "name": "d9d1e9b335877022cecc",
      "title": "indicadores"
    },
    {
      "name": "223ec1070409c9a35724",
      "title": "Vista gastos"
    },
    {
      "name": "eec06d31e04400db120c",
      "title": "Vista consumos"
    },
    {
      "name": "c1801bca0856407c50ea",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSectionfbff55f13ad9820a11b0",
    "visuals": [
    {
        "name": "5442549e9a675c5759c5",
        "title": "Distribución del costo de gas por mercado"
    },
    {
        "name": "ec82bac7223602a51e88",
        "title": "Distribución del costo de gas por tipología"
    },
    {
        "name": "bf4aa8a0a2cc7c5018c0",
        "title": "Variación %"
    },
    {
        "name": "e65c72d5d2b9a8c776b4",
        "title": "Costo de gas por mes"
    },
    {
        "name": "64964fd99a93ae2a0a5d",
        "title": "Costo año actual"
    },
    {
        "name": "b23b6902e3d06497dbe8",
        "title": "Costo año anterior"
    },
    {
        "name": "23aeda22a25740a5c0ce",
        "title": "Cuentas"
    },
    {
        "name": "c03cdda48c47a652c003",
        "title": "Distribución del costo de gas por departamento / municipio"
    },
    {
        "name": "ead8d5b34e022a93189c",
        "title": "Top costo gas"
    },
    {
      "name": "1213fe7a28cb3a5eb092",
      "title": "indicadores"
    },
    {
      "name": "63bad91ebb74bdc84ecc",
      "title": "Vista gastos"
    },
    {
      "name": "978e86c6edc073d7951a",
      "title": "Vista consumos"
    },
    {
      "name": "9415765ee1407ae0ce68",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSection157349608258dc09d880",
    "visuals": [
    {
        "name": "2db0b93992217d9cb059",
        "title": "Distribución del consumo de gas por mercado"
    },
    {
        "name": "0beb587325b790e34d04",
        "title": "Distribución del consumo de gas por tipología"
    },
    {
        "name": "07c2a552740c782c6702",
        "title": "Consumo de gas (M³) por mes"
    },
    {
        "name": "0d409e314bbbb21c5869",
        "title": "Variación %"
    },
    {
        "name": "af5e3ef0b6bc71382101",
        "title": "Consumo año actual (M³)"
    },
    {
        "name": "e30076798b1802cda924",
        "title": "Consumo año anterior (M³)"
    },
    {
        "name": "b02fd0f25e760b2a5a90",
        "title": "Cuentas"
    },
    {
        "name": "df498b6dec790a539b01",
        "title": "Distribución del consumo de gas por depto. / municipio"
    },
    {
        "name": "e6cee9234563d98be2ec",
        "title": "Top consumo gas"
    },
    {
      "name": "ef9b0fdc1a3404b037c0",
      "title": "indicadores"
    },
    {
      "name": "1317e68663d60a95d306",
      "title": "Vista gastos"
    },
    {
      "name": "7c94e7992530b1224eb1",
      "title": "Vista consumos"
    },
    {
      "name": "2c32550f80b5dc9c2081",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSectionfba9a7afd61735849dd7",
    "visuals": [
    {
        "name": "1277d54a9212dda329ce",
        "title": "Variación %"
    },
    {
        "name": "af7d1dcb9909ad1379d1",
        "title": "Tarifa prom. año actual ($/M³)"
    },
    {
        "name": "26b08d2ce0e98ad09685",
        "title": "Tarifa prom. año anterior ($/M³)"
    },
    {
        "name": "6c44912dec96a227a404",
        "title": "Cuentas"
    },
    {
        "name": "0910dcff9c0abc4c217a",
        "title": "Tarifa promedio ponderado de gas ($/M³)"
    },
    {
        "name": "851777515628531158dc",
        "title": "Top tarifas"
    },
    {
        "name": "0ff419f1b86d50047581",
        "title": "Distribución del costo de gas por mercado"
    },
    {
        "name": "ff32c5b0bd7e6503ce51",
        "title": "Distribución del costo de gas por comercializador"
    },
    {
        "name": "d2f8e74f8646eda0ed9c",
        "title": "Distribución del costo de gas por depto. / municipio"
    },
    {
      "name": "642d25290685a4579d26",
      "title": "indicadores"
    },
    {
      "name": "81449b1ed10dd08d8a6a",
      "title": "Vista gastos"
    },
    {
      "name": "1edea8e9ac1a2e5263a7",
      "title": "Vista consumos"
    },
    {
      "name": "4f79a50f40d426e8bc27",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSection9d5eb222c9b0071e2a10",
    "visuals": [
    {
        "name": "14e0052e51e7d0a90c1a",
        "title": "Variación %"
    },
    {
        "name": "c437dff0678d1d0c1162",
        "title": "KPI promedio año actual"
    },
    {
        "name": "9c4e8914bd0a79cca408",
        "title": "KPI promedio año anterior"
    },
    {
        "name": "10029c14bb691cc71d87",
        "title": "Cuentas"
    },
    {
        "name": "f4219d17e9d71092210b",
        "title": "KPI [m³/ton] por tipología"
    },
    {
        "name": "38d584705d403b717101",
        "title": "Sedes menos productivas por KPI"
    },
    {
        "name": "a828efd598a198e74613",
        "title": "KPI [m³/m²] por tipología"
    },
    {
        "name": "a55b6065edb0530d5c16",
        "title": "Seguimiento de la variación entre el año actual y anterior"
    },
    {
        "name": "26095dd50c938393693e",
        "title": "Distribución del costo de gas por tipología"
    },
    {
      "name": "e0eb48d90b20c17b1b3d",
      "title": "indicadores"
    },
    {
      "name": "d2d32d2054992481305a",
      "title": "Vista gastos"
    },
    {
      "name": "e1ce8a1a645304c87770",
      "title": "Vista consumos"
    },
    {
      "name": "f87d3e417cc64eb34556",
      "title": "Vista tarifas"
    }
    ]
},
{
    "pageName": "ReportSectioneb7f78377e70649a2230",
    "visuals": [
    {
        "name": "3a29da7b305de4a5b3c8",
        "title": "Distribución del costo de aseo por comercializador"
    },
    {
        "name": "9162643273100b8021d5",
        "title": "Distribución del costo de aseo por tipología"
    },
    {
        "name": "26c2c3a017386029bc24",
        "title": "Variación %"
    },
    {
        "name": "7f47084be64196e38b58",
        "title": "Costo de aseo por mes"
    },
    {
        "name": "5433d2efdc3bac15e3e0",
        "title": "Costo año actual"
    },
    {
        "name": "4488f114782cd030176a",
        "title": "Costo año anterior"
    },
    {
        "name": "4ea3578b6ded594a9176",
        "title": "Cuentas"
    },
    {
        "name": "a6ceff738960352667cd",
        "title": "Distribución del costo de aseo por depto. / municipio"
    },
    {
        "name": "4687ad42757bdd0b9acc",
        "title": "Top costo aseo"
    },
    {
        "name": "f8351a59e62b283c6465",
        "title": "Costos de aseo"
    }
    ]
},
{
    "pageName": "ReportSection0d4947e4aca487b07c18",
    "visuals": [
    {
        "name": "add11f9ba4841b049302",
        "title": "Distribución del costo de alumbrado por tipología"
    },
    {
        "name": "6746cc0f0186dc923845",
        "title": "Variación %"
    },
    {
        "name": "11ed42f4da8dad2691d6",
        "title": "Costo de alumbrado por mes"
    },
    {
        "name": "6f11566607608477232b",
        "title": "Costo año actual"
    },
    {
        "name": "3d690d2089ac514d8912",
        "title": "Costo año anterior"
    },
    {
        "name": "a97243767cc3b5ae25d5",
        "title": "Cuentas"
    },
    {
        "name": "76ff8a3cee6b97d15a1b",
        "title": "Distribución del costo de alumbrado por departamento / municipio"
    },
    {
        "name": "fa612333899a9684beb7",
        "title": "Top costo alumbrado"
    },
    {
        "name": "b16f796cd52504eac41e",
        "title": "Costos de alumbrado"
    }
    ]
},
{
    "pageName": "ReportSection90b004c8de081297e5b7",
    "visuals": [
    {
        "name": "d593518030c58067007e",
        "title": "Presupuesto anual por tipologia"
    },
    {
        "name": "c0a3689e58e632458201",
        "title": "Presupuesto anual"
    },
    {
        "name": "7f50023b840c80bb2b92",
        "title": "Seguimiento centro de costos"
    },
    {
        "name": "bfb1a7f5b08c866604b5",
        "title": "Seguimiento filiales"
    },
    {
        "name": "d1ca12d46c27e08b0a97",
        "title": "Seguimiento facturas año actual (año anterior)"
    },
    {
        "name": "356ad3a61b7117d2245e",
        "title": "Presupuesto"
    }
    ]
},
{
    "pageName": "ReportSection45cc35e094b0e09ee84c",
    "visuals": [
        {
        "name": "6dc66e2685906c6cee06",
        "title": "Vencimiento de facturas por día"
        },
        {
        "name": "b603d6194792a3ea0995",
        "title": "Marcado de facturas"
        },
        {
        "name": "9de01ad1c493e6c153c5",
        "title": "Exportar a excel"
        },
        {
        "name": "b429f754c46cb310e1e8",
        "title": "Pagados"
        },
        {
        "name": "befee7fbcbc877ae7ed9",
        "title": "Pendientes de pago"
        },
        {
        "name": "9cb3c6f1d70cd61103d2",
        "title": "Por vencerse"
        },
        {
        "name": "0e0cd5d3249b286398ac",
        "title": "Vencen en 2 días"
        },
        {
        "name": "c4b6cd80cbe020a910d8",
        "title": "Vencen mañana"
        },
        {
        "name": "8acc736aa8a30b01d697",
        "title": "Vencen hoy"
        },
        {
        "name": "e3b18cf3a0241549d50b",
        "title": "Información de las facturas"
        },
        {
        "name": "36afc80c02aa5bcd6740",
        "title": "Top pagos por comercializador"
        },
        {
        "name": "9b7599705400145ab85c",
        "title": "Facturas y pagos"
        }
    ]
},
{
    "pageName": "ReportSectiona05e58ca65681edd3627",
    "visuals": []
}
];

const pagesAndVisuals_rol_premium = [
    {
      "pageName": "ReportSection791741eeb468a61d3d2c",
      "visuals": [
        {
          "name": "236ee2941eda34237e4d",
          "title": "Bloqueo"
        },
        {
          "name": "d5fe4ee0091cc3beca76",
          "title": "Candado"
        },
        {
          "name": "5a535de61a07901306c0",
          "title": "Candado"
        },
        {
          "name": "5fd748244004ec365870",
          "title": "Candado"
        },
        {
          "name": "f51f17200094ebc703b0",
          "title": "Candado"
        },
        {
          "name": "08586f76c70a700a08da",
          "title": "Candado"
        },
        {
          "name": "3bb7d8010b92ccd90850",
          "title": "Candado"
        },
        {
          "name": "bab16e97449d55626989",
          "title": "Candado"
        },
        {
          "name": "410c5a745bba55080006",
          "title": "Candado"
        },
        {
          "name": "354af8f2415646862e75",
          "title": "bt2_bloqueo"
        },
        {
          "name": "f38091e4d757c7ff2545",
          "title": "bt1_bloqueo"
        },
        {
          "name": "45b1100c9392caa637a7",
          "title": "bt3_bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection2131e7c42e830b758096",
      "visuals": [
        {
          "name": "4da8862fc522b0017eb2",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection0d40d3b0b009bb988972",
      "visuals": [
        {
          "name": "a88e3d82c190e8082dc0",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection99f3c2e03d59b930e260",
      "visuals": [
        {
          "name": "0e7a06e015bc0b70a96b",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectionda54143fa97ae1586828",
      "visuals": [
        {
          "name": "717988190a96902e82e2",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectiondf28c00ed18b63371ee2",
      "visuals": [
        {
          "name": "76bec40d0d3b350b659d",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection7835c2a2e782b84690aa",
      "visuals": [
        {
          "name": "0889d0ebac968850e61c",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectionf2dbe8b0441debe85799",
      "visuals": [
        {
          "name": "5d04dc58148cedeab7a5",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection61f9066c6c2094557cb1",
      "visuals": [
        {
          "name": "636b0ed4781dcd744b49",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectionfbff55f13ad9820a11b0",
      "visuals": [
        {
          "name": "89552e09c76e072463e4",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection157349608258dc09d880",
      "visuals": [
        {
          "name": "55bdd6db9b53d6d06486",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectionfba9a7afd61735849dd7",
      "visuals": [
        {
          "name": "ea6550eeec5bee744ee4",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection9d5eb222c9b0071e2a10",
      "visuals": [
        {
          "name": "b8ec24262d2075300217",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectioneb7f78377e70649a2230",
      "visuals": [
        {
          "name": "52a38c707289094225da",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection0d4947e4aca487b07c18",
      "visuals": [
        {
          "name": "19e819bb82b389c1e066",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection45cc35e094b0e09ee84c",
      "visuals": [
        {
          "name": "f5f93064ae86c30bbd1a",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSection90b004c8de081297e5b7",
      "visuals": [
        {
          "name": "ac75c8a9247e0ed6c09b",
          "title": "Bloqueo"
        }
      ]
    },
    {
      "pageName": "ReportSectiond6303471ca6ea0003525",
      "visuals": [
        {
          "name": "d6b752dc020064c5ce41",
          "title": "Candado"
        },
        {
          "name": "ffdc5c2847c3da578880",
          "title": "Candado"
        },
        {
          "name": "ce6a1e71c338117eaab3",
          "title": "Candado"
        },
        {
          "name": "a812542785a0e0b639cd",
          "title": "Candado"
        },
        {
          "name": "f7763319119a1d7ee3ad",
          "title": "Candado"
        },
        {
          "name": "f355045fcae1d7ec544b",
          "title": "Candado"
        },
        {
          "name": "e5474ff6310c54e0dc2e",
          "title": "Candado"
        },
        {
          "name": "78cf514737d0b160a011",
          "title": "Candado"
        }
      ]
    },
    {
      "pageName": "ReportSection33e2f03944231122cd30",
      "visuals": [
        {
          "name": "ac58bdac05251541107c",
          "title": "Candado"
        },
        {
          "name": "f8f35256ca4eeab6596d",
          "title": "Candado"
        },
        {
          "name": "b9cb42aa3b5000021670",
          "title": "Candado"
        },
        {
          "name": "b22132820e606633e838",
          "title": "Candado"
        },
        {
          "name": "aee0737e4b05243026e0",
          "title": "Candado"
        },
        {
          "name": "5a9a71096386d15daae1",
          "title": "Candado"
        },
        {
          "name": "ba2c82af47c75b0398a7",
          "title": "Candado"
        },
        {
          "name": "a899a4dc0077eac7b39e",
          "title": "Candado"
        }
      ]
    }
];

const Report = ({ token, id , rol}) => {
    const id_report = '4a12ebee-f2a6-437a-812c-ae82e08b08db';
    const group_id = '8a51985e-aa59-401b-a511-d03e8d74bebf';

    const [isRefreshing, setIsRefreshing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const selectedItemsRef = useRef([]);

    useEffect(() => {}, [selectedItemsRef.current]);

    const basicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: models.FilterType.Basic,
        operator: "In",
        target: {
            table: "reportesFacturas",
            column: "ID"
        },
        values: [id]
    };

    // Conditionally set the filters array
    const custom_filters = id === "admin" ? [] : [basicFilter];

    const eventHandlers = new Map([
        ['loaded', async (event) => {
            if(rol===0){
                await removeVisuals(pagesAndVisuals_rol_freemium,rol)
            }else{
                await removeVisuals(pagesAndVisuals_rol_premium,rol)
            }
            console.log('Report loaded');
        }],
        /*['rendered', (event) => {
            //await removeVisuals(pagesAndVisuals_rol_premium,rol)
            console.log('Report rendered');
        }],*/
        ['error', (event) => console.error('Error:', event.detail)],
        ['dataSelected', (event) => handleDataSelection(event)]
    ]);

    const handleDataSelection = (event) => {
        const data = event.detail;
        if (data && data.dataPoints && data.dataPoints.length > 0) {
            const selected = data.dataPoints.map(item => {
                const id_contrato = item.identity
                    .filter(identityItem => identityItem.target.table === 'default_1' && identityItem.target.column === 'id_contrato')
                    .map(filteredItem => filteredItem.equals)[0];

                const comercializador = item.identity
                    .filter(identityItem => identityItem.target.table === 'default_1' && identityItem.target.column === 'Comercializador')
                    .map(filteredItem => filteredItem.equals)[0];

                const id_factura = item.identity
                    .filter(identityItem => identityItem.target.table === 'default_1' && identityItem.target.column === 'id_factura')
                    .map(filteredItem => filteredItem.equals)[0];

                return { 
                    id_contrato: parseInt(id_contrato), 
                    comercializador, 
                    id_factura: parseInt(id_factura) 
                };
            });

            selectedItemsRef.current = selected;
        } else {
            selectedItemsRef.current = []; // Clear selected items
        }
    };

    const handleButtonClick = async (event) => {
        if (selectedItemsRef.current.length > 0 && event.detail.id === "b603d6194792a3ea0995") {
            console.log("Button clicked with selected items: ", selectedItemsRef.current);
            setIsRefreshing(true);
            setErrorMessage('');

            // Call the Azure Function with the selected items
            const updatePromises = selectedItemsRef.current.map(item => callAzureFunction(item));

            // Wait for all promises to complete
            await Promise.all(updatePromises);

            // Attempt to refresh the report
            try {
                await refreshReport();
                console.log("Report refreshed");
                selectedItemsRef.current = []; // Clear selected items
                setIsRefreshing(false);
            } catch (error) {
                console.error("Error refreshing report: ", error);
                setErrorMessage(error.detailedMessage || 'Error refreshing report');
            }
        }

        if (event.detail.id === "9de01ad1c493e6c153c5") {
            // Export data to Excel using Power BI JavaScript API
            try {
                const page = await window.report.getPageByName("ReportSection45cc35e094b0e09ee84c")
                const visual = await page.getVisualByName("e3b18cf3a0241549d50b")

                if (visual) {
                    const result = await visual.exportData(models.ExportDataType.Summarized);
                    const blob = new Blob([result.data], { type: 'text/csv;charset=utf-8;' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'reporte.csv'; // Change extension to .csv
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    console.log("Data exported successfully");
                } else {
                    console.error("No suitable visual found for export");
                    setErrorMessage('No suitable visual found for export');
                }
            } catch (error) {
                console.error("Error exporting data: ", error);
                setErrorMessage('Error exporting data');
            }
        }
    };

    const refreshReport = async () => {
        if (window.report) {
            try {
                await window.report.refresh();
            } catch (error) {
                if (error.message === 'refreshNotAllowed') {
                    console.error("Error refreshing report: ", error);
                    setErrorMessage(error.detailedMessage || 'Error refreshing report');
                    // Retry after another delay
                    await new Promise(resolve => setTimeout(resolve, 15000)); // Wait for 15 seconds
                    await refreshReport(); // Retry refresh
                } else {
                    throw error;
                }
            }
        }
    };

    const callAzureFunction = async (item) => {
        try {
            const response = await fetch('https://sagyreportingfunctions.azurewebsites.net/api/setdbsagy', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id_contrato: item.id_contrato,
                    comercializador: item.comercializador,
                    id_factura: item.id_factura
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Azure Function response:", data);
        } catch (error) {
            console.error("Error calling Azure Function:", error);
        }
    };

    const removeVisuals = async (visualsToHide, rol) => {
        const MAX_RETRIES = 3;
        const RETRY_DELAY_MS = 1000;
    
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    
        for (const page of visualsToHide) {
            let pageObject;

            for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
                try {
                    pageObject = await window.report.getPageByName(page.pageName);
                    if (pageObject) break;
                } catch (error) {
                    if (attempt < MAX_RETRIES - 1) {
                        console.warn(`Retrying to get page ${page.pageName} (attempt ${attempt + 1})...`);
                        await delay(RETRY_DELAY_MS);
                    } else {
                        console.error(`Failed to get page ${page.pageName} after ${MAX_RETRIES} attempts:`, error);
                        continue;
                    }
                }
            }
    
            if (!pageObject) continue;

            console.log(`Procesamiento de la pagina: ${page.pageName}  --  ${pageObject.displayName}`); 
    
            if (page.pageName !== "ReportSection791741eeb468a61d3d2c" && rol === 0) {
                try {
                    console.log("*****Se elimina:" + page.pageName);
                    await window.report.deletePage(page.pageName);
                    continue;
                } catch (error) {
                    console.error(`*****Error deleting page ${page.pageName}:`, error);
                    continue;
                }
            }

            if (
              page.pageName !== "ReportSection33e2f03944231122cd30" && //Usuario
              page.pageName !== "ReportSectiond6303471ca6ea0003525" //Tramites y Gestiones
            ){
              /*Aplica filtros*/
              await pageObject.setFilters([ 
                  {
                      $schema: "http://powerbi.com/product/schema#basic",
                      filterType: models.FilterType.Basic,
                      operator: "In",
                      target: {
                          table: "reportesFacturas",
                          column: "Licencia"
                      },
                      values: [1] // Premium
                  }
              ]);
              console.log(`*****Filtros aplicados a la página: ${page.pageName}  --  ${pageObject.displayName}`);
            }
            /*---------------*/
    
            for (const visual of page.visuals) {
                for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
                    try {
                        const visualToHide = await pageObject.getVisualByName(visual.name);
                        if (visualToHide) {
                            await visualToHide.setVisualDisplayState(1);
                            console.log(`*****Visual ${visual.name} hidden on page ${page.pageName}  --  ${pageObject.displayName}`);
                            break;
                        }
                    } catch (error) {
                        if (attempt < MAX_RETRIES - 1) {
                            console.warn(`*****Retrying to hide visual ${visual.name} on page ${page.pageName}  --  ${pageObject.displayName} (attempt ${attempt + 1})...`);
                            await delay(RETRY_DELAY_MS);
                        } else {
                            console.error(`*****Failed to hide visual ${visual.name} on page ${page.pageName}  --  ${pageObject.displayName} after ${MAX_RETRIES} attempts:`, error);
                        }
                    }
                }
            }
        }
    };
  
    return (
        <div className="report-container">
            {isRefreshing && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <p>Guardando información, por favor espere...</p>
                    </div>
                </div>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',
                    id: id_report,
                    embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${id_report}&groupId=${group_id}`,
                    accessToken: token,
                    tokenType: models.TokenType.Embed,
                    filters: custom_filters,
                    settings: 
                    {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        navContentPaneEnabled: false
                    }}
                }
                eventHandlers={eventHandlers}
                cssClassName="powerbi-container"
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                    // Add event listener for the button click event
                    embeddedReport.on('buttonClicked', handleButtonClick);
                }}
            />
        </div>
    );
};

export default Report;