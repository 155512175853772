import React from 'react'
import './tyc.css'

export default function TyC() {
  return (
    <div className='ContainerTyC'>
        <h1 className='headerTyC'>POLÍTICA DE PRIVACIDAD SAGY</h1>
        <p className='detallesTyC'>La presente Política de Privacidad establece los términos en que <b className='boldTyC'>SAGY CO S.A.S.</b>, compañía identificada con NIT 901.602.443-1 y domiciliada Medellín, Colombia, usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web, whatsapp o cualquier medio digital de propiedad de <b className='boldTyC'>SAGY CO S.A.S.</b>
          <br></br><br></br>Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le requerimos diligenciar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
        </p>
        <h2 className='subtituloTyC'>Información que es recogida</h2>
        <p className='secondParrafo'>Nuestro sitio web, whatsapp o cualquier medio digital de propiedad de <b className='boldTyC'>SAGY CO S.A.S.</b> podrá recoger información personal, demográfica, profesional o laboral, por ejemplo: Nombre, correo electrónico, teléfono, ubicación, cargo, empresa, etc. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún requerimiento técnico, jurídico, administrativo o contable.
        </p>
        <h2 className='subtituloTyC'>Uso de la información recogida</h2>
        <p className='secondParrafo'>Nuestro sitio web, whatsapp o cualquier medio digital de propiedad de <b className='boldTyC'>SAGY CO S.A.S.</b> emplea la información particularmente para mantener un registro de usuarios y mejorar nuestros servicios para entregar más valor agregado. Es posible que periódicamente le sean enviados correos electrónicos a través de nuestro sitio con información de nuestro servicio, nuevas características e información que consideremos relevante o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
          <br></br><br></br> <b className='boldTyC'>SAGY CO S.A.S.</b> está altamente comprometida para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.
        </p>
        <h2 className='subtituloTyC'>Cookies</h2>
        <p className='secondParrafo'>Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.
            <br></br><br></br>Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.
        </p>
        <h2 className='subtituloTyC'>Enlaces a Terceros</h2>
        <p className='secondParrafo'>Este sitio web, whatsapp o cualquier medio digital de propiedad de <b className='boldTyC'>SAGY CO S.A.S.</b> puede contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de la protección de sus datos en estos sitios externos. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
        </p>
        <h2 className='subtituloTyC'>Control de su información personal</h2>
        <p className='secondParrafo'>En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web, whatsapp o cualquier medio digital de propiedad de <b className='boldTyC'>SAGY CO S.A.S.</b>  Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico.  En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.
        <br></br><br></br>Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.
        <br></br><br></br> <b className='boldTyC'>SAGY CO S.A.S.</b> Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
        
        </p>
    </div>
  )
}
