import React, { useState } from 'react'
import axios from 'axios'


// Import your loading GIF here
import loadingGif from "../../assets/loading.gif"; 

import './login.css'

// componentes
import ReportComponent from '../report/Report.jsx'

export default function Login() {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);
    const [rol, setRol] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State for loading status

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setIsLoading(true); // Start loading

        try {
            const response = await axios.post('https://rjkm62ow34.execute-api.us-east-1.amazonaws.com/staging/getToken/getToken', {
                "usuario" : id,
                "contrasena" : password
            });

            if (response.data && response.data.embedToken) {
                setIsLoading(false); // Stop loading after the API call is done

                setToken(response.data.embedToken);
                setRol(response.data.rol);
                
                setIsLoggedIn(true);
            } else {
                setIsLoading(false); // Stop loading after the API call is done
                setErrorMessage('Error al intentar ingresar. Por favor verifique sus credenciales.');
                console.error('Login failed:', response.data);
            }
        } catch (error) {
            setIsLoading(false); // Stop loading after the API call is done
            if (error.code === "ERR_BAD_REQUEST") {
                setErrorMessage('Error al intentar ingresar. Por favor verifique sus credenciales.');
                console.error('Login failed:', error.response.data);
            }else{
                setErrorMessage('Error al intentar ingresar. Por favor intente más tarde. código de error:' + error.code);
                console.error('Login failed:', error);
            }
        }
    };

    if (isLoggedIn) {
        return <ReportComponent token={token} id={id} rol={rol}/>;
    }

    return (
        <div className="login-page">
            {isLoading && (
                <div className="loading-container">
                    <img src={loadingGif} alt="Loading..." />
                </div>
            )}
            <div className="login-container" style={{ opacity: isLoading ? 0.5 : 1 }}>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="id">Identificador</label>
                        <input
                            type="text"
                            pattern="admin|demo|\d+"
                            title="Por favor ingrese un usuario válido."
                            id="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            type="password"
                            title="Por favor ingrese una contraseña válido."
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" disabled={isLoading}>Ingresar</button>
                </form>
            </div>
        </div>
    )
}