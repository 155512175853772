import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopBar from "./components/topbar/TopBar";
import Home from "./pages/Home";
import TyC from "./pages/TyC";
import Reporting from "./pages/Reporting";

function LayoutWithTopBar({ children }) {
  return (
    <div>
      <TopBar />
      {children}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutWithTopBar>
              <Home />
            </LayoutWithTopBar>
          }
        />
        <Route
          path="/politica-de-privacidad"
          element={
            <LayoutWithTopBar>
              <TyC />
            </LayoutWithTopBar>
          }
        />
        <Route path="/Reportes" element={<Reporting />} />
      </Routes>
    </Router>
  );
}

export default App;
